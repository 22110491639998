<template>
  <div class="d-flex clr-box" :style="!isMobSmallScreen ? 'border: 2px solid;border-radius:10px' : ''">
    <div
      class="d-flex px-2 py-2 clr-sec"
      :class="{ 'w-100 mr-2': isMobSmallScreen }"
      :style="isMobSmallScreen ? 'border: 2px solid;border-radius:10px' : ''"
    >
      <div class="position-relative">
        <Chrome
          v-if="selecting"
          v-model="selectedColor"
          v-click-outside="() => (selecting = false)"
          class="position-absolute picker"
        />
        <div class="colorr" :style="{ background: selectedColor }">
          <ion-button color="transparent" class="float-circle-btn" @click="() => (selecting = true)">
            <i class="ti-pencil-alt" style="font-size: 10px" />
          </ion-button>
        </div>
      </div>
      <div class="d-flex flex-column w-100 pl-2">
        <div class="px-2 label-box">
          <ion-textarea
            v-model="text"
            rows="1"
            no-margin
            maxlength="44"
            placeholder="label this color i.e.: Hair Color"
            class="textarea"
            @input="checkLength($event.target.value)"
          />
        </div>
        <div class="d-flex justify-content-between mt-auto mb-1">
          <div class="value clickable-item" @click="() => (selecting = true)">{{ value }}</div>

          <div
            v-if="!isMobSmallScreen && !isDraggable"
            class="d-flex justify-content-center align-items-center text-center clickable-item"
            @click.prevent="removed"
          >
            <i class="ti-trash del-icon" />
            <span class="rmv-text">delete</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isMobSmallScreen && !isDraggable"
      class="d-flex justify-content-center text-center align-items-center clickable-item"
      @click.prevent="removed"
    >
      <i class="ti-trash" style="font-size: 20px" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { isElementInViewport } from '@/shared/utils/ui';
import { toast } from '@/shared/native';

@Options({
  name: 'ColorPickerBox',
})
export default class ColorPickerBox extends Vue {
  @Prop({ default: '#FFF' }) public value!: string;
  @Prop({ default: '' }) public label!: string;
  @Prop({ default: false }) public isMobSmallScreen!: boolean;
  @Prop({ default: false }) public isDraggable!: boolean;

  public selectedColor: any = '#FFF';
  public selecting = false;
  public text = '';

  @Watch('selecting')
  public selectingChanged() {
    try {
      this.$nextTick(() => {
        const document = useDocument();
        const pickerEl = document.value?.querySelector('.vc-chrome') as HTMLElement;
        const wrapperEl = document.value?.querySelector('.scrollable') as HTMLElement;
        if (!pickerEl || !wrapperEl) return; // selecting is now false
        const { x, y } = isElementInViewport(pickerEl, wrapperEl, false);

        if (!x) {
          pickerEl.style.left = '-150px';
        }

        if (!y) {
          pickerEl.style.top = '-200px';
        }
      });
    } catch (error) {}
  }

  @Watch('value', { immediate: true })
  public change() {
    this.selectedColor = this.value;
  }

  @Watch('label', { immediate: true })
  public textChange() {
    this.text = this.label;
  }

  @Watch('text')
  public textChanged() {
    this.$emit('text-changed', this.text);
  }

  @Watch('selectedColor')
  @Emit('changed')
  public changed() {
    try {
      if (this.selectedColor.hex8) {
        return this.selectedColor.hex8;
      }
      return this.selectedColor;
    } catch (error) {
      return this.selectedColor;
    }
  }

  @Emit('removed')
  public removed() {
    return this.value;
  }

  public async checkLength(val: any) {
    if (val.length >= 44) {
      this.text = this.text.slice(0, 44);
      await toast.show("You can't write more words", 'nonative', 'danger');
    }
  }
}
</script>

<style lang="sass" scoped>
.clr-sec
  @media(max-width:680px) and (min-width: 600px)
    width: 100%
.label-box
  background: white
  border-radius: 8px
.dark .label-box
  background: #11022a
.clr-box
  width: calc(35% - 21px)
  @media(max-width:1185px) and (min-width:980px)
    width: calc(35% - 118px)
  @media(max-width:980px)
    width: calc(35% - 24px)
  @media(max-width:772px) and (min-width: 700px)
    width: calc(35% - 18px)
  @media(max-width:680px) and (min-width:480px)
    width: calc(52% - 18px) !important
  @media(max-width:480px)
    width: 100% !important
.dark .textarea
  color: white !important
.textarea
  height: 42px !important
  min-height: 42px !important
  border-radius: 10px !important
  border: 2px none #CCC !important
  width: unset

  --highlight-color-focused: none !important
  ::v-deep
    textarea
      border: 0px none !important
      min-height: 42px !important
      &:focus
        highlight-color-focused: none !important
.textarea
  border: 0px none !important
  min-height: 42px !important
  --highlight-color-focused: none !important
  ::v-deep
    textarea
      border: 0px none !important
      --padding-top: 4px
      --padding-bottom: 0px
      font-size: 12px !important
      min-height: 42px !important
      &:focus
        highlight-color-focused: none !important
.picker
  margin-top: 63px
  z-index: 11111
.del-icon
  font-size: 10px
  color: var(--ion-color-primary)
  margin-top: 3px
  margin-right: 0.15rem
.dark .del-icon
  color: var(--ion-color-primary) !important
.rmv-text
  font-size: 11px
  color: var(--ion-color-primary)
.float-circle-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 5px !important
  border-radius: 5px !important
  width: 23px
  height: 23px
  --padding: 0
  --padding-start: 0
  --padding-end: 0
  left: 20.5px
  top: 15px
  position: relative
  z-index: 1
.remove-color-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 20px !important
  height: 20px !important
  right: calc(-100% + 28px)
.colorr
  width: 63px
  height: 63px
  font-size: 10px
  position: relative
  border: 2px solid black,
  border-radius: 10px
.value
  width: 100%
  font-size: 10px
  word-wrap: break-word
  margin-top: 1px
</style>
